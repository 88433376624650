import * as React from 'react'
// import { useEffect,useState } from 'react';
import { Link } from "gatsby"
// import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Logo from "@svgs/logo.svg"

const Header = () => {
  
  return (
    <>
      <header>
        <div className="header">

          <Link
            to="/"
            aria-label="logo"
            className='logo'
          >
            <Logo
              alt="ル・サンク小田原栄町"
            />
          </Link>

          <div className="link-box">
            <Link
              to="/map/"
              className='link-map'
            >
              <span>現地案内図</span>
            </Link>
            <a href="https://www.haseko-sumai.com/asp/syuto/sinki/n-odawara/gaiyou.asp" target="_blank" rel="noopener noreferrer" className='link-out'>
              <span>物件概要</span>
            </a>
            <a href="https://www.haseko-sumai.com/asp/syuto/sinki/n-odawara/request.asp" target="_blank" rel="noopener noreferrer" className='link-req'>
              <span>資料請求</span>
            </a>
            <a href="https://airrsv.net/AKR0034741846/calendar" target="_blank" rel="noopener noreferrer" className='link-res'>
              <span>来場予約</span>
            </a>
          </div>

        </div>
      </header>
    </>
  )
}
export default Header