import * as React from 'react'
import { Link } from "gatsby"
// import AniLink from 'gatsby-plugin-transition-link/AniLink'

const NavLink = ({ link, className, text, toggle }) => {
  
  return (
    <>
      <li
        className={className}
      >
        <Link
          direction="down"
          to={link}
          onClick={toggle}
        >
          <span>{text}</span>
        </Link>
      </li>
    </>
  )
}
export default NavLink