import * as React from 'react'
// import { useEffect } from 'react';
import { Link } from "gatsby"
import IconMap from "@svgs/icon_link_map.svg"
import IconOut from "@svgs/icon_link_out.svg"
import IconReq from "@svgs/icon_link_req.svg"
import IconRes from "@svgs/icon_link_res.svg"

const FloatNav = ({ close }) => {

  return (
    <>
      <div className="float-nav _nav" id="floatNav">
        <div className="flex-box">
          <Link
            to="/map/"
            className='link-map'
            onClick={close}
          >
            <span><IconMap /></span>
            <p>現地案内図</p>
          </Link>
          <a href="https://www.haseko-sumai.com/asp/syuto/sinki/n-odawara/gaiyou.asp" target="_blank" rel="noopener noreferrer" className='link-out'>
            <span><IconOut /></span>
            <p>物件概要</p>
          </a>
          <a href="https://www.haseko-sumai.com/asp/syuto/sinki/n-odawara/request.asp" target="_blank" rel="noopener noreferrer" className='link-req'>
            <span><IconReq /></span>
            <p>資料請求</p>
          </a>

          <a href="https://airrsv.net/AKR0034741846/calendar" target="_blank" rel="noopener noreferrer" className='link-res'>
            <span><IconRes /></span>
            <p>来場予約</p>
          </a>
        </div>
      </div>
    </>
  )
}
export default FloatNav