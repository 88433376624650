import * as React from 'react'
// import { useState, useEffect } from 'react';
import { useLocation } from "@reach/router"
import { NavLink } from '@inc/index'

const Nav = ({toggle, open}) => {

  const location = useLocation().pathname;
  let actPage;
  console.log(location);
  if (location === "/") {
    actPage = "top"
  } else if (location === "/concept/") {
    actPage = "concept"
  } else if (location === "/attractiveness/") {
    actPage = "attractiveness"
  } else if (location === "/access/") {
    actPage = "access"
  } else if (location === "/design/") {
    actPage = "design"
  } else if (location === "/plan/") {
    actPage = "plan"
  } else if (location === "/company/") {
    actPage = "company"
  } else if (location === "/map/") {
    actPage = "map"
  } else if (location === "/404/" || location === "/404.html") {
    actPage = "not-found"
  }else{
    actPage = "plan"
  }

  return (
    <>
      <nav className={`${open ? 'act' : ''}`}>
        <ul
          className={actPage}
        >
          <NavLink
            link="/"
            className="top"
            text="TOP"
            toggle={toggle}
          />
          <NavLink
            link="/concept/"
            className="concept"
            text="コンセプト"
            toggle={toggle}
          />
          <NavLink
            link="/attractiveness/"
            className="attractiveness"
            text="東口の魅力"
            toggle={toggle}
          />
          <NavLink
            link="/access/"
            className="access"
            text="アクセス"
            toggle={toggle}
          />
          <NavLink
            link="/design/"
            className="design"
            text="デザイン"
            toggle={toggle}
          />
          <NavLink
            link="/plan/"
            className="plan"
            text="間取り"
            toggle={toggle}
          />
          <NavLink
            link="/company/"
            className="company"
            text="会社紹介"
            toggle={toggle}
          />
        </ul>
      </nav>


      <button className={`c-nav_btn _nav ${open ? 'act' : ''}`} onClick={toggle}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </>
  )
}
export default Nav